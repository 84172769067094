import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  IconButton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FieldType } from '@common/entities';
import { newUuid } from '@common/services';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { NotificationStatus } from 'enums';
import SmartRoutesTopActions from 'views/smart-routes/SmartRoutesTopActions';

import { TrashIcon } from 'components/custom-icons';
import Loader from 'components/Loader';
import { ConfirmationModal } from 'components/modal';
import notification from 'components/notification';
import SmartRouteFieldRenderer from 'components/smart-routes/SmartRouteFieldRenderer';
import { useApplications } from 'hooks/queries/useApplications';
import { addSingleField, deleteField, patchField } from 'services';
import { getSmartRoute } from 'services/smart-routes';
import { getAvailableSteps } from 'utils/smart-routes/getAvailableSteps';

interface QuestionElement {
  id: string;
  name: string;
  label: string;
  sequence: number;
  type: string;
  options: any[];
}

const findDependentQuestions = (questions, questionIdToDelete) => {
  return questions.filter(
    (question) =>
      question.id !== questionIdToDelete &&
      question.options?.some(
        (option) => option.target?.id === questionIdToDelete,
      ),
  );
};

const DeleteConfirmationMessage = ({
  questions,
  questionToDelete,
  dependentQuestions,
}) => {
  const itemToDelete = questions.find((q) => q.id === questionToDelete);
  const isStaticText = itemToDelete?.type === FieldType.StaticText;

  if (isStaticText) {
    return 'Are you sure you want to delete this message?';
  }

  const questionNumber =
    questions
      .filter((q) => !q.type.includes(FieldType.StaticText))
      .findIndex((qst) => qst.id === questionToDelete) + 1;

  if (dependentQuestions.length === 0) {
    return `Are you sure you want to delete Question ${questionNumber}?`;
  }

  return (
    <Box>
      <Text mb={2}>
        Are you sure you want to delete Question {questionNumber}?
      </Text>
      <Text mb={2}>
        This will remove Question {questionNumber} as the next step in:
      </Text>
      {dependentQuestions.map((el) => {
        const depQuestionNumber =
          questions
            .filter((q) => !q.type.includes(FieldType.StaticText))
            .findIndex((qst) => qst.id === el.id) + 1;
        return (
          <Text key={el.id} ml={4}>
            Qualifying Question {depQuestionNumber}
          </Text>
        );
      })}
    </Box>
  );
};

function ViewSmartRoute() {
  const { routeId, versionId } = useParams();
  const methods = useForm();
  const { trigger } = methods;

  const queryClient = useQueryClient();
  const [questions, setQuestions] = useState<QuestionElement[]>([]);
  const [sectionId, setSectionId] = useState<string>('');
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState<string | null>(null);
  const [dependentQuestions, setDependentQuestions] = useState<
    QuestionElement[]
  >([]);
  const [isOptionConfirmOpen, setIsOptionConfirmOpen] = useState(false);
  const [optionToDelete, setOptionToDelete] = useState<{
    questionId: string;
    optionId: string;
  } | null>(null);

  const { data: applicationsData } = useApplications();

  const { isPending, data, fetchStatus, refetch } = useQuery({
    queryKey: ['smart-route', routeId],
    queryFn: () => getSmartRoute(routeId, versionId),
    enabled: !!routeId && !!versionId,
  });

  const { mutateAsync: addOptionField } = useMutation({
    mutationFn: addSingleField,
    onError: (err) => {
      notification('Error', err.message, NotificationStatus.ERROR);
    },
  });

  const { mutateAsync: updateField } = useMutation({
    mutationFn: patchField,
  });

  const { mutateAsync: deleteQuestion, isPending: isDeleting } = useMutation({
    mutationFn: deleteField,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['smart-route', routeId] });
    },
    onError: (err) => {
      notification('Error', err.message, NotificationStatus.ERROR);
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch, versionId]);

  useEffect(() => {
    const firstSection =
      data?.data?.versions?.[0]?.form?.pages?.[0]?.sections?.[0];
    if (firstSection) {
      setSectionId(firstSection.id);
      setQuestions(firstSection.elements || []);
    }
  }, [data]);

  if (isPending && fetchStatus !== 'idle') return <Loader />;

  const handleAddQuestion = async (
    questionType: 'message' | 'question' | 'dropdown',
  ) => {
    try {
      let title: string;
      let fieldType: string;
      let label: string;
      let namePrefix: string;
      if (questionType === 'message') {
        title = `Message`;
        fieldType = 'static-text';
        label = title;
        namePrefix = 'message';
      } else {
        title = `Qualifying Question`;
        fieldType = FieldType.Dropdown;
        label = 'Qualifying question';
        namePrefix = 'question';
      }

      if (questions.length === 0) {
        throw new Error('No section available.');
      }
      const newFieldSequence = questions.length + 1;

      const optionPayload = {
        sectionId,
        customType: '',
        customField: true,
        label,
        name: `${namePrefix}_${newFieldSequence}_option_${newUuid()}`,
        required: true,
        sequence: newFieldSequence,
        type: fieldType,
        ...(questionType !== 'message' && {
          options: [
            {
              name: `option_1_${newUuid()}`,
              label: 'Option 1',
              sequence: 1,
            },
            {
              name: `option_2_${newUuid()}`,
              label: 'Option 2',
              sequence: 2,
            },
          ],
        }),
      };

      const response = await addOptionField(optionPayload);

      const newField = {
        id: response?.data?.id,
        name: `${namePrefix}_${newFieldSequence}`,
        label,
        sequence: newFieldSequence,
        type: fieldType,
        options: response?.data?.options || optionPayload.options,
      };

      setQuestions((prevQuestions) => [...prevQuestions, newField]);
    } catch (err: any) {
      notification(
        'Error',
        err?.message || 'Failed to add question.',
        NotificationStatus.ERROR,
      );
    }
  };

  const handleAddOption = async (questionId: string) => {
    const currentQuestion = questions.find((q) => q.id === questionId);
    if (!currentQuestion) return;

    try {
      const lastOption =
        currentQuestion.options[currentQuestion.options.length - 1];
      const newOptionSequence = lastOption ? lastOption.sequence + 1 : 1;

      const newOption = {
        name: `option_${newUuid()}`,
        label: `Option ${newOptionSequence}`,
        sequence: newOptionSequence,
      };

      const response = await patchField({
        id: currentQuestion.id,
        options: [...currentQuestion.options, newOption],
      });

      const updatedQuestion = response?.data?.options
        ? { ...currentQuestion, options: response.data.options }
        : currentQuestion;

      setQuestions((prevQuestions) =>
        prevQuestions.map((q) => (q.id === questionId ? updatedQuestion : q)),
      );
    } catch (err: any) {
      notification(
        'Error',
        err?.message || 'Failed to add option.',
        NotificationStatus.ERROR,
      );
    }
  };

  const handleDeleteQuestion = async () => {
    if (!questionToDelete) return;
    try {
      await deleteQuestion(questionToDelete);
      setQuestions((prev) => prev.filter((q) => q.id !== questionToDelete));
    } catch (err) {
      notification(
        'Error',
        err?.message || 'Failed to delete question.',
        NotificationStatus.ERROR,
      );
    } finally {
      setIsConfirmOpen(false);
      setQuestionToDelete(null);
    }
  };

  const handleDeleteClick = (questionId) => {
    const dependents = findDependentQuestions(questions, questionId);
    setDependentQuestions(dependents);
    setQuestionToDelete(questionId);
    setIsConfirmOpen(true);
  };

  const handleConfirmDeleteOption = async () => {
    if (!optionToDelete) return;

    const { questionId, optionId } = optionToDelete;
    const currentQuestion = questions.find((q) => q.id === questionId);
    if (!currentQuestion) return;

    try {
      const updatedOptions = currentQuestion.options.filter(
        (option) => option.id !== optionId,
      );

      await patchField({ id: currentQuestion.id, options: updatedOptions });

      setQuestions((prevQuestions) =>
        prevQuestions.map((q) =>
          q.id === questionId ? { ...q, options: updatedOptions } : q,
        ),
      );

      queryClient.invalidateQueries({ queryKey: ['smart-route', routeId] });
    } catch (err: any) {
      notification(
        'Error',
        err?.message || 'Failed to delete option.',
        NotificationStatus.ERROR,
      );
    } finally {
      setIsOptionConfirmOpen(false);
      setOptionToDelete(null);
    }
  };

  const handleDeleteOption = (questionId: string, optionId: string) => {
    setOptionToDelete({ questionId, optionId });
    setIsOptionConfirmOpen(true);
  };

  const validateBeforePublish = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return false;
    }
    return true;
  };

  const versions = data?.data?.versions || [];
  const isDraftMode = versions.length > 0 && versions[0].status === 'draft';

  return (
    <FormProvider {...methods}>
      <Box as="form" mb="100px">
        <SmartRoutesTopActions
          data={data?.data}
          validateBeforePublish={validateBeforePublish}
          refetch={refetch}
        />
        <Stack spacing={6} my={6}>
          {(() => {
            let staticTextCount = 0;
            let qualifyingQuestionCount = 0;

            return questions.map((question) => {
              const { id, name, type } = question;
              const isOpeningMessage =
                name === 'application_route_opening_message';
              const isStaticText = type === 'static-text';

              let heading = '';
              if (isOpeningMessage) {
                heading = 'Opening Message';
              } else if (isStaticText) {
                staticTextCount += 1;
                heading = `Message ${staticTextCount}`;
              } else {
                qualifyingQuestionCount += 1;
                heading = `Qualifying Question ${qualifyingQuestionCount}`;
              }
              return (
                <Card key={id}>
                  <CardHeader pb={2} display="flex" gap={2} alignItems="center">
                    <Heading>{heading}</Heading>
                    {!isOpeningMessage && isDraftMode && (
                      <IconButton
                        aria-label="trash"
                        variant="ghost"
                        fontSize="26px"
                        color="red.700"
                        icon={<TrashIcon />}
                        onClick={() => handleDeleteClick(id)}
                      />
                    )}
                  </CardHeader>
                  <CardBody>
                    <SmartRouteFieldRenderer
                      section={{ id, elements: [question] }}
                      onChangeHandler={(elementId, newLabel) => {
                        setQuestions((prev) =>
                          prev.map((q) =>
                            q.id === elementId ? { ...q, label: newLabel } : q,
                          ),
                        );
                      }}
                      onDeleteOption={(optionId) =>
                        handleDeleteOption(id, optionId)
                      }
                      getAvailableSteps={() =>
                        getAvailableSteps(questions, applicationsData, id)
                      }
                      updateField={updateField}
                      setQuestions={setQuestions}
                      onAddQuestion={handleAddQuestion}
                      isDisabled={!isDraftMode}
                    />
                    {!isStaticText && isDraftMode && (
                      <Button onClick={() => handleAddOption(id)}>
                        Add Option
                      </Button>
                    )}
                  </CardBody>
                </Card>
              );
            });
          })()}
        </Stack>

        {isDraftMode && (
          <HStack justifyContent="center" gap={4}>
            <Button mt={4} onClick={() => handleAddQuestion('dropdown')}>
              Add Question
            </Button>
            <Button mt={4} onClick={() => handleAddQuestion('message')}>
              Add Message
            </Button>
          </HStack>
        )}
        <ConfirmationModal
          isOpen={isConfirmOpen}
          onClose={() => {
            setIsConfirmOpen(false);
            setQuestionToDelete(null);
            setDependentQuestions([]);
          }}
          onSubmit={handleDeleteQuestion}
          message={
            <DeleteConfirmationMessage
              questions={questions}
              questionToDelete={questionToDelete}
              dependentQuestions={dependentQuestions}
            />
          }
          actionLabel="Delete"
          actionColor="red.500"
          isLoading={isDeleting}
        />
        <ConfirmationModal
          isOpen={isOptionConfirmOpen}
          onClose={() => setIsOptionConfirmOpen(false)}
          onSubmit={handleConfirmDeleteOption}
          message="Are you sure you wish to delete this option?"
          actionLabel="Delete"
          actionColor="red.500"
          isLoading={isDeleting}
        />
      </Box>
    </FormProvider>
  );
}

export default ViewSmartRoute;
