/* istanbul ignore file */
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  SimpleGrid,
  Textarea,
} from '@chakra-ui/react';
import { FieldType } from '@common/entities';
import { useQueryClient } from '@tanstack/react-query';
import { NotificationStatus } from 'enums';

import { CirclePlusIcon, TrashIcon } from 'components/custom-icons';
import notification from 'components/notification';

import SmartRouteNextStepDropDown from './SmartRouteNextStepDropDown';

const SmartRouteFieldRenderer = ({
  section,
  onChangeHandler,
  onDeleteOption,
  getAvailableSteps,
  updateField,
  setQuestions,
  onAddQuestion,
  isDisabled,
}) => {
  const { elements } = section;
  const queryClient = useQueryClient();

  // Initialize React Hook Form with default values
  const {
    control,
    formState: { dirtyFields },
    reset,
  } = useForm({
    defaultValues: elements.reduce(
      (acc, { id, label, options = [] }) => ({
        ...acc,
        [`${id}_label`]: label,
        ...options.reduce(
          (optAcc, opt) => ({
            ...optAcc,
            [`${id}_option_${opt.id}`]: opt.label,
          }),
          {},
        ),
      }),
      {},
    ),
    mode: 'onBlur',
  });

  /**
   * Handles field updates only if the field is dirty.
   */
  const handleBlurUpdate = async ({
    id,
    newValue,
    updateKey,
    options = undefined,
    fieldName,
  }) => {
    if (!dirtyFields[fieldName]) {
      return;
    }

    const updatePayload = options
      ? { id, [updateKey]: newValue, options }
      : { id, [updateKey]: newValue };

    try {
      await updateField(updatePayload);

      setQuestions((prevQuestions) =>
        prevQuestions.map((q) =>
          q.id === id
            ? { ...q, [updateKey]: newValue, ...(options && { options }) }
            : q,
        ),
      );

      reset(
        (prevValues) => ({
          ...prevValues,
          [fieldName]: newValue,
        }),
        { keepValues: true },
      );
    } catch (err) {
      queryClient.invalidateQueries({ queryKey: ['smart-route'] });
      notification(
        'Error',
        err.message || `Failed to update ${updateKey}. Data re-synced.`,
        NotificationStatus.ERROR,
      );
    }
  };

  const handleStepSelection = async (fieldId, optionId, selectedStepValue) => {
    const availableGroups = getAvailableSteps(optionId);
    const allOptions = availableGroups.reduce(
      (acc, group) => acc.concat(group.children),
      [],
    );
    const fullSelectedStep = allOptions.find(
      (option) => option.value === selectedStepValue.value,
    );
    const isApplication = fullSelectedStep.type === 'application';

    const newTarget = { id: fullSelectedStep.value };

    try {
      const updatedOptions = section.elements
        .find((el) => el.id === fieldId)
        ?.options.map((option) =>
          option.id === optionId
            ? {
                ...option,
                routeToApplicationId: isApplication
                  ? fullSelectedStep.value
                  : null,
                routeToFieldId: isApplication ? null : fullSelectedStep.value,
                target: newTarget,
              }
            : option,
        );

      await updateField({
        id: fieldId,
        options: updatedOptions,
      });

      setQuestions((prevQuestions) =>
        prevQuestions.map((q) =>
          q.id === fieldId ? { ...q, options: updatedOptions } : q,
        ),
      );
    } catch (err) {
      notification(
        'Error',
        err.message || 'Failed to update step.',
        NotificationStatus.ERROR,
      );
    }
  };

  return (
    <Box>
      {elements.map(({ id, type, options = [] }) => (
        <Box key={id} mb={6}>
          {type === FieldType.StaticText ? (
            <Box gridColumn="span 4" minH="120px" maxW="800px">
              <Controller
                name={`${id}_label`}
                control={control}
                render={({ field }) => (
                  <Textarea
                    {...field}
                    placeholder="Message Text"
                    resize="none"
                    minH="120px"
                    isDisabled={isDisabled}
                    onChange={(e) => {
                      field.onChange(e);
                      onChangeHandler(id, e.target.value);
                    }}
                    onBlur={(e) => {
                      field.onBlur();
                      handleBlurUpdate({
                        id,
                        newValue: e.target.value,
                        updateKey: 'label',
                        fieldName: `${id}_label`,
                      });
                    }}
                  />
                )}
              />
            </Box>
          ) : (
            <Box>
              <FormControl mb={4} maxW="800px">
                <FormLabel>Question</FormLabel>
                <Controller
                  name={`${id}_label`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      isDisabled={isDisabled}
                      onBlur={(e) => {
                        field.onBlur();
                        handleBlurUpdate({
                          id,
                          newValue: e.target.value,
                          updateKey: 'label',
                          options,
                          fieldName: `${id}_label`,
                        });
                      }}
                    />
                  )}
                />
              </FormControl>

              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, xl: 4 }}
                spacing={4}
                justifyContent="start"
              >
                {options.map(
                  ({ id: optionId, sequence: optionSequence, name }) => (
                    <Flex key={optionId} maxW="400px" pos="relative" pl="30px">
                      <Box
                        as="span"
                        position="absolute"
                        left="0px"
                        top="38px"
                        w="20px"
                        h="20px"
                        borderRadius="20px"
                        border="1px solid"
                        borderColor="gray.500"
                      />
                      <Box
                        as="span"
                        position="absolute"
                        left="9px"
                        bottom="24px"
                        top="58px"
                        w="21px"
                        borderLeft="1px solid"
                        borderBottom="1px solid"
                        borderColor="gray.500"
                        borderBottomLeftRadius="xl"
                      />

                      <Box w="100%">
                        <FormControl>
                          <FormLabel>Option {optionSequence}</FormLabel>
                          <Controller
                            name={`${id}_option_${optionId}`}
                            control={control}
                            render={({ field }) => (
                              <Input
                                {...field}
                                mb={4}
                                placeholder={`Option ${optionSequence}`}
                                isDisabled={isDisabled}
                                onBlur={(e) => {
                                  field.onBlur();
                                  const updatedOptions = options.map(
                                    (option) =>
                                      option.id === optionId
                                        ? { ...option, label: e.target.value }
                                        : option,
                                  );
                                  handleBlurUpdate({
                                    id,
                                    newValue: updatedOptions,
                                    updateKey: 'options',
                                    options: updatedOptions,
                                    fieldName: `${id}_option_${optionId}`,
                                  });
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl>
                          <SmartRouteNextStepDropDown
                            name={name}
                            placeholder="Select Step"
                            label="Next Step"
                            options={getAvailableSteps(optionId)}
                            isDisabled={isDisabled}
                            defaultValue={
                              options.find((option) => option.id === optionId)
                                ?.target?.id ?? ''
                            }
                            onChange={(selectedStep) =>
                              handleStepSelection(id, optionId, selectedStep)
                            }
                            actionButtons={[
                              {
                                label: 'Add a Message',
                                onClick: () => {
                                  if (onAddQuestion) onAddQuestion('message');
                                },
                                icon: CirclePlusIcon,
                                iconColor: 'green.500',
                              },
                              {
                                label: 'Add a Qualifying Question',
                                onClick: () => {
                                  if (onAddQuestion) onAddQuestion('dropdown');
                                },
                                icon: CirclePlusIcon,
                                iconColor: 'green.500',
                              },
                            ]}
                            required
                          />
                        </FormControl>
                      </Box>

                      {options.length > 2 && !isDisabled && (
                        <IconButton
                          mt={6}
                          ml={2}
                          aria-label="trash"
                          variant="ghost"
                          fontSize="26px"
                          color="red.700"
                          icon={<TrashIcon />}
                          onClick={() => {
                            if (!optionId) {
                              notification(
                                'Error',
                                'Option ID is missing and cannot be deleted.',
                                NotificationStatus.ERROR,
                              );
                              return;
                            }
                            onDeleteOption(optionId);
                          }}
                        />
                      )}
                    </Flex>
                  ),
                )}
              </SimpleGrid>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default SmartRouteFieldRenderer;
