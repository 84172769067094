import { BlockType } from '@common/entities';
import { get } from 'config';

const AFFORDABLE_END_POINTS = '/affordable';

interface GetAffordableCompanyDocument {
  custom?: { id: number; name: string }[];
  system?: { id: number; name: string }[];
}
export const getAffordableCompanyDocument = async (
  propertyId: number,
  type: BlockType,
) => {
  if (!propertyId || !type) {
    throw new Error(
      'Something went wrong while fetching document. Please try again.',
    );
  }
  const response = await get<GetAffordableCompanyDocument>(
    `${AFFORDABLE_END_POINTS}/company-documents/${propertyId}/${type}`,
  );
  return response?.data;
};
