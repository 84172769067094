import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import { DropDownOption } from '../type';

// eslint-disable-next-line import/no-cycle
import RenderGroupOptions from './RenderGroupOptions';

interface Props {
  options: DropDownOption[];
  subSection?: number;
  onChange: (data: string) => void;
  value: string;
  isTruncated?: boolean;
}
const RenderOptionList = (props: Props) => {
  const { options, onChange, value, subSection = 0, isTruncated } = props;

  return options.map((option, index) => {
    const isSelected = value === option?.value;
    if (option?.children?.length) {
      return (
        <RenderGroupOptions
          key={`${option?.children[0].value}${index}`}
          option={option}
          value={value}
          onChange={onChange}
          subSection={subSection}
          isTruncated={isTruncated}
        />
      );
    }
    return (
      <Box
        my={1}
        key={option?.value}
        data-testid={`option-${index}-${option.label.replaceAll(' ', '-')}`}
        bg={isSelected ? 'blue.500' : 'transparent'}
        color={isSelected ? 'white.base' : 'black.darkest'}
        py={isSelected ? 2 : 1}
        px={subSection ? 4 + subSection : 2}
        borderRadius="md"
        alignItems="center"
        onClick={() => onChange(option?.value)}
        fontSize={12}
        cursor="pointer"
      >
        <Text whiteSpace="break-spaces" isTruncated={isTruncated}>
          {option.label}
        </Text>
      </Box>
    );
  });
};

export default RenderOptionList;
