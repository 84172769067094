/* istanbul ignore file */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ChevronDownIcon, ChevronUpIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
  Text,
} from '@chakra-ui/react';

import RenderOptionList from 'components/fields/custom-fields/drop-down-group/single-select/RenderOptionList';
import { FieldWrapper } from 'components/fields/FieldWrapper';
import { recursiveSearch } from 'utils/recursiveSearch';

interface Props {
  name: string;
  label?: string;
  required?: boolean;
  placeholder: string;
  options: any[];
  onChange: (data: any) => void;
  defaultValue?: string;
  isReadOnly?: boolean;
  width?: number | string;
  isDisabled?: boolean;
  actionButtons?: {
    label: string;
    onClick: () => void;
    icon?: React.ElementType;
    iconColor?: string;
  }[];
}

const SmartRouteNextStepDropDown = (props: Props) => {
  const {
    name,
    label,
    options,
    required = false,
    onChange,
    defaultValue,
    placeholder = '',
    isReadOnly = false,
    width = 300,
    isDisabled = false,
    actionButtons = [],
  } = props;

  const { control, clearErrors, setValue } = useFormContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [fieldValue, setFieldValue] = useState<string>(defaultValue || '');
  const [searchStr, setSearchStr] = useState('');
  const ref = useRef(null);

  const handleClickOutside = useCallback((event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [handleClickOutside]);

  useEffect(() => {
    if (defaultValue && !fieldValue) {
      setFieldValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const selectedObject = useCallback(recursiveSearch, []);

  const filterValues = (acc: any[], curr: any) => {
    if (curr.children) {
      const children = curr.children.reduce(filterValues, []);
      if (children?.length) {
        return [...acc, { ...curr, children }];
      }
      return acc;
    }
    const fieldLabel = curr.label.toLowerCase();
    if (fieldLabel.includes(searchStr.toLowerCase())) {
      return [...acc, curr];
    }
    return acc;
  };

  const filterList = useCallback(() => {
    return options.reduce(filterValues, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, searchStr]);

  /**
   * To get the placeholder text
   */
  const getPlaceholderText = () => {
    if (fieldValue === '') return placeholder ?? 'Select Option';
    const selectedOption = selectedObject(options, 'value', fieldValue);
    if (!selectedOption) return '';
    return selectedOption?.label;
  };

  const handleSelection = (val: string) => {
    if (isReadOnly) return;
    setFieldValue(val);
    const fullOption = selectedObject(options, 'value', val);
    onChange && onChange(fullOption);
    clearErrors(name);
    setValue(name, val, { shouldValidate: true });
    setIsOpen(false);
  };

  const renderOption = filterList();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      rules={{ required: required ? 'This field is required.' : false }}
      render={({ fieldState }) => {
        return (
          <FieldWrapper
            name={name}
            label={label}
            required={required}
            fieldState={fieldState}
          >
            <Box position="relative" width={width}>
              <Flex
                border="1px solid"
                borderColor={fieldState.invalid ? 'red.500' : 'gray.500'}
                fontSize={14}
                px={3}
                py={3}
                h="48px"
                bg="white.base"
                borderRadius={8}
                onClick={() => {
                  if (isDisabled) return;
                  setIsOpen((prevState) => !prevState);
                }}
                cursor={isDisabled ? 'not-allowed' : 'pointer'}
              >
                <Text whiteSpace="nowrap" overflowX="hidden">
                  {getPlaceholderText()}
                </Text>
                <Spacer />
                {isOpen ? (
                  <ChevronUpIcon boxSize={5} />
                ) : (
                  <ChevronDownIcon boxSize={5} />
                )}
              </Flex>

              <Box
                bg="white.base"
                border="1px solid"
                borderColor="gray.100"
                borderRadius="lg"
                ref={ref}
                position="absolute"
                width="100%"
                maxH="380px"
                display={isOpen ? 'block' : 'none'}
                zIndex={1}
              >
                {/* Search Bar */}
                <Box p={2} bg="white.base" position="sticky" top="0" zIndex={2}>
                  <InputGroup>
                    <InputLeftElement h="100%" pointerEvents="none">
                      <SearchIcon fontSize="13px" />
                    </InputLeftElement>
                    <Input
                      isDisabled={isDisabled}
                      placeholder="Search"
                      onChange={(e) => setSearchStr(e.target.value)}
                    />
                  </InputGroup>
                </Box>

                {/* Scrollable Options */}
                <Box
                  overflowY="auto"
                  flex="1"
                  maxH={`calc(360px - ${actionButtons.length > 0 ? '70px' : '0px'} - 56px)`}
                  p={2}
                >
                  <RenderOptionList
                    options={renderOption}
                    onChange={handleSelection}
                    value={fieldValue}
                    isTruncated
                  />
                </Box>

                {actionButtons.length > 0 && (
                  <Box
                    bottom="0"
                    bg="white.base"
                    p={2}
                    zIndex={2}
                    fontSize="sm"
                  >
                    {actionButtons.map((button, index) => (
                      <Flex
                        key={index}
                        alignItems="center"
                        justifyContent="space-between"
                        p={2}
                        _hover={{ bg: 'gray.100', cursor: 'pointer' }}
                        onClick={() => {
                          button.onClick();
                          setIsOpen(false);
                        }}
                      >
                        <Text>{button.label}</Text>
                        {button.icon && (
                          <Icon as={button.icon} color={button.iconColor} />
                        )}
                      </Flex>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          </FieldWrapper>
        );
      }}
    />
  );
};

export default SmartRouteNextStepDropDown;
