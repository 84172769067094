"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertSnakeCaseToCamelCase = void 0;
var convertSnakeCaseToCamelCase = function (str) {
    var index = str.indexOf('_');
    if (index < 0)
        return str;
    var newStr = str.substring(0, index) + str.substring(index + 1);
    if (newStr.length < index + 1) {
        return newStr.replace('_', '');
    }
    var updatedStr = newStr.substring(0, index) +
        newStr[index].toUpperCase() +
        newStr.substring(index + 1);
    return (0, exports.convertSnakeCaseToCamelCase)(updatedStr);
};
exports.convertSnakeCaseToCamelCase = convertSnakeCaseToCamelCase;
