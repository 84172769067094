/* istanbul ignore file */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  HStack,
} from '@chakra-ui/react';
import { FormType } from '@common/entities';
import { EntrataSession } from 'auth/EntrataSession';
import { VIEWER_URL } from 'config/URL';
import { RootState } from 'state/store';

import { PencilIcon } from 'components/custom-icons';

interface PreviewResponsiveWrapperProps {
  formType: FormType;
}

export function PreviewResponsiveWrapper({
  formType,
}: PreviewResponsiveWrapperProps) {
  const [iframeSize, setIframeSize] = useState('100%');
  const [activeButton, setActiveButton] = useState('desktop');

  const selectedApplication = useSelector(
    (state: RootState) => state.application.selectedApplication,
  );
  const name = useSelector(
    (state: RootState) => state.application.selectedRoute?.name,
  );

  const { applicationId, routeId, versionId } = useParams();
  const navigate = useNavigate();

  const isRouteForm = formType === FormType.Route;

  const applicationName = isRouteForm ? name : selectedApplication?.name;

  const editUrl = isRouteForm
    ? `/routes/route/${routeId}/${versionId}`
    : `/application/form-builder/${applicationId}/${versionId}`;

  const previewUrl = isRouteForm
    ? `${VIEWER_URL}/previewroute/${routeId}/${versionId}`
    : `${VIEWER_URL}/preview/${versionId}?user=${encodeURIComponent(JSON.stringify(EntrataSession.user))}`;

  const handleSizeChange = (size: string, button: string) => {
    setIframeSize(size);
    setActiveButton(button);
  };

  return (
    <Flex
      direction="column"
      bg="gray.highlighthalf"
      flexBasis="100%"
      height="calc(100% - 30px)"
    >
      <Flex
        direction="column"
        flex="1"
        bg="gray.highlighthalf"
        maxW="1800px"
        w="100%"
        m="auto"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          py={3}
          px={5}
        >
          <Heading size="2xl">{applicationName}</Heading>
          <HStack spacing={4}>
            <Button
              leftIcon={<PencilIcon boxSize={5} />}
              onClick={() => navigate(editUrl)}
            >
              Edit
            </Button>
            <Button variant="action" size="lg" onClick={() => navigate(`/`)}>
              Exit
            </Button>
          </HStack>
        </Flex>
        <Box
          flex="1"
          display="flex"
          flexDirection="column"
          maxW="1700px"
          m="auto"
          w="100%"
        >
          <ButtonGroup>
            <Button
              onClick={() => handleSizeChange('100%', 'desktop')}
              as="span"
              role="button"
              bg={activeButton === 'desktop' ? 'white.base' : 'gray.highlight'}
              borderRadius="none"
              borderTopLeftRadius="xl"
              borderTopRightRadius="xl"
              height="unset"
              px={8}
            >
              Desktop
            </Button>
            <Button
              onClick={() => handleSizeChange('390px', 'mobile')}
              as="span"
              role="button"
              bg={activeButton === 'mobile' ? 'white.base' : 'gray.highlight'}
              borderRadius="none"
              borderTopLeftRadius="xl"
              borderTopRightRadius="xl"
              height="unset"
              px={8}
            >
              Mobile
            </Button>
          </ButtonGroup>

          <Box w="100%" bg="white.base" px={3} py={2} h="100%">
            <Box
              w={iframeSize}
              h={activeButton === 'mobile' ? '844px' : '100%'}
              margin="auto"
              borderWidth="1px"
              borderColor={
                activeButton === 'mobile' ? 'gray.light' : 'white.base'
              }
              overflow="hidden"
              bg="white.base"
            >
              <iframe
                src={previewUrl}
                width="100%"
                height="100%"
                title="Responsive view"
                style={{ display: 'block' }}
              />
            </Box>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default PreviewResponsiveWrapper;
