import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { FormVersionStatus, PropertyRoute } from '@common/entities';
import { useMutation } from '@tanstack/react-query';
import { NotificationStatus } from 'enums';

import { PencilIcon, SyncIcon, ViewIcon } from 'components/custom-icons';
import { ConfirmationModal } from 'components/modal/ConfirmationModal';
import notification from 'components/notification';
import {
  createRouteDraft,
  getPropertyRoutesOverridenByRoute,
  publishRouteVersion,
} from 'services';

export function SmartRoutesTopActions({ data, refetch }: any) {
  const { name, versions } = data;
  const { routeId, versionId } = useParams();
  const navigate = useNavigate();

  const currentVersion = versions?.[0] || {};
  const isDraft = currentVersion?.status === FormVersionStatus.Draft;
  const isActive = currentVersion?.status === FormVersionStatus.Active;

  const { trigger } = useFormContext();
  const [modalText, setModalText] = useState<React.ReactElement | string>('');
  const [apiResult, setApiResult] = useState<boolean | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDraftOpen,
    onOpen: onDraftOpen,
    onClose: onDraftClose,
  } = useDisclosure();

  // Publish a Route
  const { isPending: isPublishing, mutateAsync: publish } = useMutation({
    mutationFn: async () =>
      publishRouteVersion({
        routeId,
        versionId: currentVersion.id,
      }),
    onSuccess: () => {
      setApiResult(true);
      notification(
        'Published',
        'The route has been successfully published!',
        NotificationStatus.SUCCESS,
      );
      onClose();
      navigate('/');
    },
    onError: (err: any) => {
      setApiResult(false);
      notification(
        'Error',
        err.message || 'Something went wrong while publishing.',
        NotificationStatus.ERROR,
      );
    },
  });

  // Create a Draft
  const { isPending: isDrafting, mutateAsync: createDraft } = useMutation({
    mutationFn: async () =>
      createRouteDraft({
        routeId,
        versionId: currentVersion.id,
      }),
    onSuccess: (response) => {
      const newDraftVersionId = response.data?.versions?.[0]?.id;

      if (!newDraftVersionId) {
        notification(
          'Error',
          'Failed to create a draft version.',
          NotificationStatus.ERROR,
        );
        return;
      }

      setApiResult(true);
      notification(
        'Draft Created',
        'A new draft version has been created.',
        NotificationStatus.SUCCESS,
      );

      setApiResult(null);
      onDraftClose();

      navigate(`/routes/route/${routeId}/${newDraftVersionId}`);
    },
    onError: (err: any) => {
      setApiResult(false);
      notification(
        'Error',
        err.message || 'Failed to create a draft.',
        NotificationStatus.ERROR,
      );
      setApiResult(null);
    },
  });

  /**
   * Handles Publishing the Route
   */
  async function handlePublish() {
    if (!routeId || !currentVersion.id) {
      notification(
        'Error',
        'Missing route or version information.',
        NotificationStatus.ERROR,
      );
      return;
    }

    const isValid = await trigger();
    if (!isValid) {
      notification(
        'Error',
        'Please select a next step for each option before proceeding.',
        NotificationStatus.ERROR,
      );
      return;
    }

    const propertyRoutesOverriden =
      await getPropertyRoutesOverridenByRoute(routeId);
    if (propertyRoutesOverriden?.data?.length) {
      const routes = propertyRoutesOverriden.data as PropertyRoute[];
      let routeData = '';
      routes.forEach((element) => {
        routeData += `${element.propertyName} - ${element.routeName}\n`;
      });
      setModalText(
        <>
          <Text textAlign="left">
            When this Smart App Route is published, any existing Route
            associations will be replaced with this new Route.
          </Text>
          <Text textAlign="left" fontWeight="bold" paddingTop="5">
            Properties Assigned to Existing Routes:
          </Text>
          <Text textAlign="left" whiteSpace="pre-line">
            {routeData}
          </Text>
          <Text textAlign="left" paddingTop="5">
            Would you like to continue?
          </Text>
        </>,
      );
    } else {
      setModalText(
        'Publishing will make this route active for all properties associated with it. Would you like to continue?',
      );
    }

    onOpen();
  }

  /**
   * Handles Creating a Draft
   */
  async function handleCreateDraft() {
    if (!routeId || !currentVersion.id) {
      notification(
        'Error',
        'Missing route or version information.',
        NotificationStatus.ERROR,
      );
      return;
    }
    setModalText('This will create a new draft based from this version.');
    onDraftOpen();
  }

  async function handleExit() {
    refetch();
    navigate('/');
  }

  const openPreview = () => {
    navigate(`/routes/preview/${routeId}/${versionId}`);
  };

  return (
    <Box>
      <Flex py={7} px={5} justifyContent="space-between">
        <HStack spacing={35}>
          <Tooltip label={name} isDisabled={name?.length < 25}>
            <Heading size="2xl">{name}</Heading>
          </Tooltip>

          {currentVersion?.status && (
            <Text color="black.base" fontSize={11}>
              <SyncIcon boxSize={4} /> {currentVersion.status}&nbsp;
              {new Date(currentVersion.updatedDate).toLocaleString()}
            </Text>
          )}
        </HStack>

        <HStack spacing={4}>
          <Button
            mr={2}
            leftIcon={<ViewIcon boxSize={6} />}
            onClick={openPreview}
          >
            Preview
          </Button>
          {isActive && (
            <Button
              leftIcon={<PencilIcon boxSize={5} />}
              onClick={handleCreateDraft}
            >
              Edit
            </Button>
          )}
          {isDraft && (
            <Button
              variant="action"
              size="lg"
              onClick={handlePublish}
              isLoading={isPublishing}
            >
              Publish
            </Button>
          )}

          <Button size="lg" onClick={handleExit}>
            Exit
          </Button>
        </HStack>
      </Flex>

      {/* Publish Confirmation Modal */}
      <ConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        message={modalText}
        actionLabel="Continue"
        actionColor="blue.700"
        onSubmit={publish}
        apiStatus={apiResult}
        isLoading={isPublishing}
      />

      {/* Draft Confirmation Modal */}
      <ConfirmationModal
        isOpen={isDraftOpen}
        onClose={onDraftClose}
        message={modalText}
        actionLabel="Continue"
        actionColor="blue.700"
        onSubmit={createDraft}
        apiStatus={apiResult}
        isLoading={isDrafting}
      />
    </Box>
  );
}

export default SmartRoutesTopActions;
