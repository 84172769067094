import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

function Loader() {
  return (
    <Flex
      position="fixed"
      top={0}
      bottom={0}
      left={0}
      right={0}
      zIndex={10000}
      justifyContent="center"
      alignItems="center"
      backdropFilter="blur(4px)"
      background="rgba(255, 255, 255, 0.2)"
      data-testid="loading"
    >
      <Spinner size="xl" />
    </Flex>
  );
}

export default Loader;
