import React, { useState } from 'react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Collapse, Flex, Text } from '@chakra-ui/react';

import { DropDownOption } from '../type';

// eslint-disable-next-line import/no-cycle
import RenderOptionList from './RenderOptionList';

interface Props {
  option: DropDownOption;
  value: string;
  onChange: (data: string) => void;
  subSection?: number;
  isTruncated?: boolean;
}
const RenderGroupOptions = ({
  option,
  value,
  onChange,
  subSection = 0,
  isTruncated,
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Box>
      <Flex
        bg="gray.100"
        p={1}
        px={2}
        mt={1}
        ml={subSection ? 1 + subSection : 0}
        borderRadius="md"
        alignItems="center"
        justifyContent="space-between"
        cursor="pointer"
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <Text whiteSpace="break-spaces" fontSize={12}>
          {option.label}
        </Text>
        {isOpen ? <ChevronRightIcon /> : <ChevronDownIcon />}
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Box>
          <RenderOptionList
            options={option.children}
            value={value}
            onChange={onChange}
            subSection={subSection + 2}
            isTruncated={isTruncated}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default RenderGroupOptions;
