export interface RecursiveSearch {
  value: string;
  children?: RecursiveSearch[];
}
export const recursiveSearch = (
  arr: RecursiveSearch[],
  key: string,
  value: string | number,
) => {
  for (let index = 0; index < arr.length; index += 1) {
    const element = arr[index];
    // eslint-disable-next-line eqeqeq
    if (element[key] == value) return element;

    if (element.children) {
      const found = recursiveSearch(element.children, key, value);
      if (found) return found;
    }
  }
  return null;
};
